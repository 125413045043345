import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';

import { getBanner } from '../services/otherServices';
import { fetchHomepageBlogs, selectHomepageBlogs } from '../redux/slice/homepageBlogs';
import { getHomepageCustomListVer2 } from '../services/courseServices';
import { selectMasterclassesCourses } from '../redux/slice/masterclassesAndCoursesSlice';

import HeaderBanner from '../components/common/HeaderBanner';
import CommonNavbar from '../components/common/header/CommonNavbar';
import Hero from '../components/presentation/home/Hero';
import Companies from '../components/common/Companies';
import AlumniNetwork from '../components/presentation/home/AlumniNetwork';
import Explore from '../components/presentation/home/Explore';
import Mentors from '../components/presentation/home/Mentors';
import ParticipantsCompanies from '../components/presentation/home/ParticipantsCompanies';
import MasterclassesCategoryWise from '../components/presentation/home/MasterclassesCategoryWise';
import MasterclassesListView from '../components/presentation/home/MasterclassesListView';
import CoursesListView from '../components/presentation/home/CoursesListView';
import GeneralFAQ from '../components/common/GeneralFAQ';
import Footer from '../components/common/footer/Footer';
import WhatSetUsApart from '../components/presentation/home/WhatSetUsApart';
import HeroSection from '../components/presentation/home/HeroSection';
import BecomeInstructor2 from '../components/presentation/home/BecomeInstructor2';
import EmailSubscriptionVer2 from '../components/common/EmailSubscriptionVer2';
import CoursesList from '../components/presentation/home/CoursesList';
import ThumbnailListing from '../components/presentation/home/ThumbnailListing';
import Testimonials from '../components/presentation/home/Testimonials';
import SmoothScroller from '../components/SmoothScroller';


const CounsellingForm = dynamic(() => import('../components/common/CallbackFormVer2'), { ssr: false });

const metaTags = {
    title: 'Upskill Your Architectural Design Career | Certified with Kaarwan',
    description:
        'Unlock your career potential with our result oriented & affordable upskilling programs for architects, interior designers, graphic designers & UI-UX designers. Learn from industry experts and advance your career with Kaarwan.',
    keywords:
        'Architecture courses,Interior design courses,Graphic design courses,UI/UX design courses,Career development,Upskilling,Professional development,Online learning,Design education,Architectural technology',
    image: 'https://www.kaarwan.com/kaarwan/static-seo-img/kaarwan-homepage.webp',
    url: 'https://www.kaarwan.com',
};


export async function getServerSideProps() {
    let _banner = {
        content: "",
        title: "",
    }
    try {
        const resp = await getBanner();
        _banner = {
            content: resp.data.data ? resp.data.data[0]?.content : '',
            link: resp.data.data ? resp.data.data[0]?.link : '',
        };
    } catch (error) {
        _banner = {
            content: "",
            link: ""
        }
    }
    return {
        props: {
            banner: _banner
        }
    }
}

const Index = ({ banner }) => {
    const dispatch = useDispatch();


    const { success: homepageBlogsSuccess } = useSelector(selectHomepageBlogs);

    const [callbackOpen, setCallbackOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth > 768) {
                setCallbackOpen(true);
            }
        }
    }, []);

    useEffect(() => {
        if (!homepageBlogsSuccess) {
            dispatch(fetchHomepageBlogs());
        }
    }, [homepageBlogsSuccess, dispatch]);

    const [open, setOpen] = useState(false);

    const [sections, setSections] = useState([]);

    const fetchCustomListing = async () => {
        try {
            const { data } = await getHomepageCustomListVer2();
            if (data.data && data.data.length) {
                const _sections = Array.from(data.data);
                setSections(_sections);
            } else {
                setSections([])
            }
        } catch (error) {
            console.log("Something went wrong::>", error)
        }
    }

    useEffect(() => {
        fetchCustomListing()
    }, [])

    const { courses } = useSelector(selectMasterclassesCourses);

    return (
        <>
            <Head>
                <title>{metaTags.title}</title>

                <meta name='title' content={metaTags.title} />
                <meta name='description' content={metaTags.description} />
                <meta name='keywords' content={metaTags.keywords} />

                <meta property='og:title' content={metaTags.title} />
                <meta property='og:description' content={metaTags.description} />
                <meta property='og:keywords' content={metaTags.keywords} />
                <meta property='og:image' content={metaTags.image} />
                <meta property='og:url' content={process.env.NEXT_PUBLIC_SITE_URL} />
                <meta property='og:type' content='education' />

                <meta property='twitter:card' content='summary_large_image' />
                <meta property='twitter:url' content={metaTags.url} />
                <meta property='twitter:title' content={metaTags.title} />
                <meta property='twitter:description' content={metaTags.description} />
                <meta property='twitter:image' content={metaTags.image} />

                <link rel='canonical' href={metaTags.url} />
                <link rel='image_src' href={metaTags.image} />
            </Head>
            <main>
                <div className='home-container'>
                    <HeaderBanner headerId='kwn-sticky-header-1' banner={banner} />
                    <CommonNavbar classes='homepage-header' />
                    <HeaderBanner headerId='kwn-sticky-header-2' banner={banner} btnClose />

                    <div className='home-hero-section-desktop'>
                        <HeroSection />
                    </div>
                    <div className='home-hero-section-mobile'>
                        <Hero />
                    </div>
                    <Companies />
                    <div id='long-term-courses'>
                        <CoursesList data={courses.COURSES && courses.COURSES.length ? courses.COURSES : []} />
                    </div>
                    <WhatSetUsApart />

                    <MasterclassesCategoryWise id='featured-courses' data={courses.MASTERCLASSES.length ? courses.MASTERCLASSES : []} />

                    {sections && sections.length
                        ? Array.from(sections).map((item) => (
                            <div key={item?.id}>
                                <>
                                    {item?.cardType === 1 ? (
                                        <>
                                            {item?.type === 1 ? (
                                                <MasterclassesListView sectionData={item} />
                                            ) : (
                                                <CoursesListView sectionData={item} />
                                            )}
                                        </>
                                    ) : (
                                        <ThumbnailListing sectionData={item} />
                                    )}
                                </>
                            </div>
                        ))
                        : null}
                    <AlumniNetwork />
                    <Testimonials />
                    <Mentors />
                    <ParticipantsCompanies />
                    <BecomeInstructor2 />
                    <Explore />
                    <GeneralFAQ />
                    <EmailSubscriptionVer2 />
                    <Footer classes='eduvibe-footer-one' />
                    <CounsellingForm showChat={false} isCallbackOpen={callbackOpen} open={open} setOpen={setOpen} utmType={9} defer />
                    <SmoothScroller />
                </div>
            </main>
        </>
    );
};

export default Index;
