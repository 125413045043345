import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import ArchitectureIcon from '../../svg-icons/all-workshops/ArchitectureIcon';
import InteriorDesignIcon from '../../svg-icons/all-workshops/InteriorDesignIcon';
import GeneralDesignIcon from '../../svg-icons/all-workshops/GeneralDesign';
import { BiRightArrowAlt } from 'react-icons/bi';
import { IoSearch } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { getWorkshopURL, removeDuplicates } from '../../../utils';
import CONSTANTS from '../../../utils/constants';
import Image from 'next/image';
import { selectMasterclassesCourses } from '../../../redux/slice/masterclassesAndCoursesSlice';

const HeroSection = () => {

    const ITEMS = [
        {
            id: 4,
            title: "Upto <span>350%</span> salary <i class='line-break'></i> hike for Architects",
            icon: 'salary-hike.svg',
            classes: 'item-1',
        },
        {
            id: 3,
            title: "<span>Job support</span> <i class='line-break'></i> Certification programs",
            icon: 'job-support.svg',
            classes: 'item-2',
        },
        {
            id: 1,
            title: "Tech upskilling for <i class='line-break'></i> <span>Architects</span>",
            icon: 'skill-development.svg',
            classes: 'item-3',
        },
        {
            id: 2,
            title: "Shift to <span>High-paying</span> <i class='line-break'></i> Design career",
            icon: 'design-career.svg',
            classes: 'item-4',
        },
    ];

    const [showSearchResult, setShowResult] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [query, setQuery] = useState('');

    const { courses } = useSelector(selectMasterclassesCourses);

    const [showingTopSellings, setShowingTopSellings] = useState(false);

    const searchHandle = (e) => {
        const { value } = e.target;

        const key = String(value).toLowerCase();

        if (key !== '') {
            const _result = Array.from(courses.ALL).filter((item) =>
                String(item?.name + ' ' + item?.searchKeys + ' ' + item?.url + ' ' + item?.keywords)
                    .toLowerCase()
                    .includes(key)
            );
            setQuery(value);

            setSearchResult(removeDuplicates(_result, "id"));
            if (_result.length) {
                setShowResult(true);
                setShowingTopSellings(false);
            } else {
                setShowingTopSellings(true);
                setSearchResult(courses.TOP_SELLINGS);
            }
        } else {
            setShowResult(false);
            setQuery('');
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const txts = document.querySelector('.animate-text').children,
                txtsLen = txts.length;
            let index = 0;
            const textInTimer = 2500,
                textOutTimer = 2300;

            const animateText = () => {
                for (let i = 0; i < txtsLen; i++) {
                    txts[i].classList.remove('text-in', 'text-out');
                }
                txts[index].classList.add('text-in');

                setTimeout(() => {
                    txts[index].classList.add('text-out');
                }, textOutTimer);

                setTimeout(() => {
                    if (index == txtsLen - 1) {
                        index = 0;
                    } else {
                        index++;
                    }
                    animateText();
                }, textInTimer);
            };

            animateText();
        }
    }, []);

    const searchInputRef = useRef();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('click', (e) => {
                if (!e.target.id) {
                    setShowResult(false);
                    setSearchResult([]);
                }
            });
        }
    }, []);

    return (
        <div className='home-hero version-2' id='homepage_hero_section'>
            <div className='container hero-background'>
                <div className='main-section edu-section-gap kw-section-gap'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-7 col-md-12'>
                            <h1 className='title'>Upskill & Advance your Design Career</h1>
                            <div className='hero-search-wrapper'>
                                <div className='input-wrapper d-flex align-items-center' id='heroSearchInput'>
                                    <span className='prepend-icon'>
                                        <IoSearch color='#ff6575' strokeWidth={5} />
                                    </span>
                                    <input
                                        type='text'
                                        onChange={searchHandle}
                                        value={query}
                                        placeholder='Search courses in'
                                        className='border-0'
                                        ref={searchInputRef}
                                        id='heroSearchInput'
                                    />
                                    <section
                                        className='home'
                                        onClick={() => {
                                            searchInputRef.current.focus();
                                        }}
                                        style={{ zIndex: query ? -100 : 1 }}
                                    >
                                        <div className='home-text d-flex align-items-center'>
                                            <p className='animate-text'>
                                                <span>Architecture</span>
                                                <span>Interior Design</span>
                                                <span>UI-UX Design</span>
                                            </p>
                                        </div>
                                    </section>

                                    {!query ? (
                                        <span className='append-icon d-flex align-items-center justify-content-center'>
                                            <BiRightArrowAlt size={22} color='white' strokeWidth={1} />
                                        </span>
                                    ) : (
                                        <button
                                            type='button'
                                            name='btnClearText'
                                            className='bg-transparent border-0'
                                            onClick={() => {
                                                setQuery('');
                                                setShowResult(false);
                                                setSearchResult([]);
                                                setShowingTopSellings(false);
                                                searchInputRef.current.value = '';
                                            }}
                                        >
                                            <CloseIcon />
                                        </button>
                                    )}
                                </div>

                                {showSearchResult ? (
                                    <div
                                        className={`hero-search-result ${showingTopSellings ? 'top-sellings' : ''}`}
                                        id='heroSearchResult'
                                    >
                                        {showingTopSellings ? (
                                            <div className='header-content'>
                                                <p className='recommended-label m-0'>
                                                    No courses found, Try different keywords.
                                                </p>
                                                <h6 className='header m-0'>
                                                    Courses that we think you'll find valuable
                                                </h6>
                                            </div>
                                        ) : null}
                                        {searchResult.slice(0, showingTopSellings ? 3 : 2).map((item) => (
                                            <Link
                                                href={getWorkshopURL(item?.type, item?.name, item?.id, item?.url, '')}
                                                key={item?.id}
                                            >
                                                <a
                                                    className='course-link d-flex align-items-center'
                                                    style={{ marginBottom: '16px' }}
                                                >
                                                    <div className='image-wrapper'>
                                                        <Image
                                                            alt={item?.name}
                                                            width={40}
                                                            height={40}
                                                            objectFit='cover'
                                                            src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                            className='course-thumb'
                                                            priority
                                                        />
                                                    </div>
                                                    <span className='title ms-4'>{item?.name}</span>
                                                </a>
                                            </Link>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                            <div
                                style={{
                                    opacity: showSearchResult ? 0 : 1,
                                    pointerEvents: showSearchResult ? 'none' : 'all',
                                }}
                            >
                                <ul className='list-unstyled filter-chips d-flex align-items-center'>
                                    <li>
                                        <Link href='/courses-and-workshops?tag=architecture#explore'>
                                            <a className='d-flex align-items-center text-decoration-none text-nowrap'>
                                                <ArchitectureIcon />
                                                Architecture
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/courses-and-workshops?tag=interior-design#explore'>
                                            <a className='d-flex align-items-center text-decoration-none text-nowrap'>
                                                <InteriorDesignIcon /> Interior Design
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/courses-and-workshops?tag=ui-ux-design#explore'>
                                            <a className='d-flex align-items-center text-decoration-none text-nowrap'>
                                                <GeneralDesignIcon /> UI-UX Design
                                            </a>
                                        </Link>
                                    </li>
                                </ul>

                                <div className='rating-and-reviews d-flex justify-content-between'>
                                    <div className='google-badge'>
                                        <Image
                                            src='/kaarwan/google-for-education-badge.png'
                                            alt='google-for-education-badge'
                                            width={150}
                                            height={65}
                                            objectFit='cover'
                                            priority
                                        />
                                    </div>
                                    <div className=''>
                                        <span className='m-0 d-flex align-items-center heading'>
                                            Rated 4.9 on{' '}
                                            <a
                                                href={CONSTANTS.KWN_FB_PAGE}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className='text-primary text-decoration-underline ms-2'
                                            >
                                                Facebook
                                            </a>
                                        </span>
                                        <span>Trusted by over 44K+ learners worldwide</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='background-container'>

                                <div className='items-container'>
                                    <Image
                                        src='/constant/homepage/backgrounds/kaarwan-home-page-hero-background.webp'
                                        alt=''
                                        layout='fill'
                                        className='background-image'
                                        priority={true}
                                    />
                                    {ITEMS.map((item) => (
                                        <div
                                            className={`item-bar ${item.classes} d-flex align-items-center text-nowrap`}
                                            key={item.id}
                                        >
                                            <img src={`/constant/homepage/icons/${item.icon}`} alt='' />

                                            <p dangerouslySetInnerHTML={{ __html: item.title }} className='m-0 p-0'></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='mobile-cta'>
                                <Link href='/courses-and-workshops#explore'>
                                    <a className='courses-cta kw-btn-orange d-flex align-items-center justify-content-center text-nowrap'>
                                        Explore Courses
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;

const CloseIcon = (props) => (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M18 6L6 18' stroke='#FF6575' strokeWidth={2.3} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6 6L18 18' stroke='#FF6575' strokeWidth={2.3} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);
