import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import CourseCardThree from '../../cards/CourseCardThree';
import { removeDuplicates } from '../../../utils';

const CarouselSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    lazyLoad: 'progressive',
    swipeToSlide: true,
    swipe: true,
    touchMove: true,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                rows: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                slidesPerRow: 1,
                rows: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
                slidesPerRow: 1,
                rows: 1,
            },
        },
    ],
};

const MasterclassesCategoryWise = ({ data: courses, id = 'featured-courses' }) => {
    const [activeTag, setActiveTag] = useState('all');
    const [allCourses, setAllCourses] = useState({
        all: [],
        arch: [],
        int: [],
        uiux: [],
    });


    const courseCategories = useSelector((state) => state.categories?.data?.courseCategories);

    useEffect(() => {
        if (courses) {
            const _courses = removeDuplicates(courses, "id");

            const _archs = _courses.filter(
                (item) =>
                    String(getTagName(item?.Category?.name)).toLowerCase() === String('architecture').toLowerCase()
            );

            const _ints = _courses.filter(
                (item) =>
                    String(getTagName(item?.Category?.name)).toLowerCase() === String('interior-design').toLowerCase()
            );

            const _uiuxs = _courses.filter(
                (item) =>
                    String(getTagName(item?.Category?.name)).toLowerCase() === String('ui-ux-design').toLowerCase()
            );

            setAllCourses({
                all: [..._courses],
                arch: _archs,
                int: _ints,
                uiux: _uiuxs,
            });
        }
    }, [courses]);

    const categoryChangeHandle = (cat) => {
        setActiveTag(cat);
    };

    const getTagName = (_activeTag) => {
        return String(_activeTag).split(' ').length === 1
            ? String(_activeTag).toLowerCase()
            : String(_activeTag).split(' ').join('-').toLowerCase();
    };

    const [sliderRef, setSliderRef] = useState();

    if (!courses.length) {
        return <></>;
    }
    return (
        <div className='masterclasses-category-wise' id={id}>
            <div className='edu-section-gap kw-section-gap'>
                <div className='container'>
                    <div className='section-title text-center'>
                        <h3 className='title text-light'>
                            Our powerful <span className='text-highlight'>Masterclasses</span> to unlock your Potential!
                        </h3>
                    </div>
                </div>

                <div className='container tabs-container'>
                    <ul className='filter-tags list-unstyled d-flex align-items-center justify-content-center'>
                        <li>
                            <button
                                type='button'
                                className={`text-capitalize rounded-pill text-nowrap  border-0 ${activeTag === 'all' ? 'active' : ''
                                    }`}
                                onClick={() => categoryChangeHandle('all')}
                            >
                                all
                            </button>
                        </li>

                        {courseCategories &&
                            courseCategories.length > 0 &&
                            courseCategories.slice(0, 3).map((item) => (
                                <li key={item?.id}>
                                    <button
                                        type='button'
                                        className={`text-capitalize rounded-pill text-nowrap  border-0 ${activeTag === getTagName(item?.name) ? 'active' : ''
                                            }`}
                                        onClick={() => categoryChangeHandle(getTagName(item?.name))}
                                    >
                                        {item.name}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </div>

                <div className='container'>
                    {allCourses.all.length && activeTag === 'all' ? (
                        <Slider
                            className='slick-activation-wrapper course-activation-3 edu-slick-button'
                            asNavFor={sliderRef}
                            ref={(slider1) => setSliderRef(slider1)}
                            {...CarouselSettings}
                        >
                            {allCourses.all.map((item, ind) => (
                                <div className='single-slick-card' key={ind}>
                                    <CourseCardThree wsInfo={item} />
                                </div>
                            ))}
                        </Slider>
                    ) : null}

                    {activeTag === 'architecture' ? (
                        <Slider
                            className='slick-activation-wrapper course-activation-3 edu-slick-button'
                            asNavFor={sliderRef}
                            ref={(slider1) => setSliderRef(slider1)}
                            {...CarouselSettings}
                        >
                            {allCourses.arch &&
                                allCourses.arch.length &&
                                allCourses.arch.map((item, ind) => (
                                    <div className='single-slick-card' key={ind}>
                                        <CourseCardThree wsInfo={item} />
                                    </div>
                                ))}
                        </Slider>
                    ) : null}

                    {activeTag === 'interior-design' ? (
                        <Slider
                            className='slick-activation-wrapper course-activation-3 edu-slick-button'
                            asNavFor={sliderRef}
                            ref={(slider1) => setSliderRef(slider1)}
                            {...CarouselSettings}
                        >
                            {allCourses.int &&
                                allCourses.int.length &&
                                allCourses.int.map((item, ind) => (
                                    <div className='single-slick-card' key={ind}>
                                        <CourseCardThree wsInfo={item} />
                                    </div>
                                ))}
                        </Slider>
                    ) : null}

                    {activeTag === 'ui-ux-design' ? (
                        <Slider
                            className='slick-activation-wrapper course-activation-3 edu-slick-button'
                            asNavFor={sliderRef}
                            ref={(slider1) => setSliderRef(slider1)}
                            {...CarouselSettings}
                        >
                            {allCourses.uiux &&
                                allCourses.uiux.length &&
                                allCourses.uiux.map((item, ind) => (
                                    <div className='single-slick-card' key={ind}>
                                        <CourseCardThree wsInfo={item} />
                                    </div>
                                ))}
                        </Slider>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MasterclassesCategoryWise;
