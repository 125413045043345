import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Link from 'next/link';
import Image from 'next/image';

import { useSelector } from 'react-redux';
import { getPaymentUrl, getPrices, getShortTitle, getWorkshopURL, getWsPricing } from '../../utils';
import { RupeeSymbol } from './CourseCardTwo';
import { selectCurrency } from '../../redux/slice/currencySlice';
import { useRouter } from 'next/router';
import { selectMasterclassesCourses } from '../../redux/slice/masterclassesAndCoursesSlice';

const CourseCardThree = ({ wsInfo }) => {
    const { courses } = useSelector(selectMasterclassesCourses);

    const [data, setData] = useState(null)

    useEffect(() => {
        if (wsInfo.id && courses.ALL && courses.ALL.length) {
            setData(courses.ALL.find((i) => i.id === wsInfo.id))
        }
    }, [courses.ALL, wsInfo.id])

    const { currency: _currencyType } = useSelector(selectCurrency);

    const { _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, _IS_RECORDED } = getPrices(data);

    const _VALUE = _currencyType === 'INR' ? _INR_PRICE : _USD_PRICE;
    const _CURRENCY = _currencyType === 'INR' ? 'INR' : 'USD';

    const date =
        moment(data?.Batches && data?.Batches[0]?.startDate)
            .format('MMM DD')
            .toString()
            .toLowerCase() === 'invalid date'
            ? 'Coming soon'
            : moment(data?.Batches[0]?.startDate).format('MMM DD');

    const handleEvent = () => {
        !!window.fbq &&
            window.fbq('track', 'AddToCart', {
                content_name: data?.name,
                value: _VALUE,
                content_type: 'Product',
                currency: _CURRENCY,
            });
        window.gtag('event', 'add_to_cart', {
            currency: _CURRENCY,
            value: _VALUE,
            items: [
                {
                    item_name: data?.name,
                    price: _VALUE,
                    quantity: 1,
                },
            ],
            event_category: 'button',
            event_label: 'add_to_cart',
        });
    };

    const CURRENT_DATE = moment(new Date()).format("YYYY-MM-DD");

    let COUPON = data?.CouponDetails && data?.CouponDetails.length && data?.CouponDetails[0]?.Coupon ? data?.CouponDetails[0]?.Coupon : null;
    COUPON = COUPON && new Date(COUPON?.endDate) >= new Date(CURRENT_DATE) || COUPON?.endDate === '0000-00-00' ? COUPON : null;

    const router = useRouter();
    let _params = '';
    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });
    if (!data) return;


    return (
        <div className='course-card-3'>
            <div className='card-thumb'>
                <Link href={getWorkshopURL(1, data?.name, data?.id, data?.url, _params)}>
                    <a>
                        <div className='image-wrapper'>
                            <Image
                                src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${data?.image}`}
                                alt={data?.name}
                                layout='fill'
                            // loading='lazy'
                            />
                        </div>
                    </a>
                </Link>
                <span
                    className={`category ${String(data?.Category?.name).split(' ').at(0).toLowerCase()} rounded-pill`}
                >
                    {data?.Category?.name}
                </span>
                {COUPON && _CURRENCY === 'INR' ? (
                    <span className='kwn-ccdb rounded-pill text-dark d-flex align-items-center justify-content-center'>
                        <RupeeSymbol /> {COUPON?.discount} Off
                    </span>
                ) : null}
            </div>

            <ul className='meta-info list-unstyled m-0'>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/clock-8.svg' alt='days' />
                    <span>{data?.days}</span>
                </li>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/timer-3.svg' alt='duration' />
                    <span>{data?.hours}</span>
                </li>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/calendar-8.svg' alt='date' />
                    <span>{_IS_RECORDED ? 'Recorded' : date}</span>
                </li>
            </ul>
            <h3 className='title'>
                <Link href={getWorkshopURL(1, data?.name, data?.id, data?.url, _params)}>
                    <a className='two-line-title'>
                        {getShortTitle(data?.shortTitleType, data?.shortTitle, data?.name, 2)}
                    </a>
                </Link>
            </h3>

            <div className='pricing-details d-flex justify-content-between align-items-center'>
                <div className='price'>
                    <div className='d-flex align-items-baseline'>
                        <span className='original-price'>
                            {getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, true)}
                        </span>
                        <span className='offer-price'>
                            {getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT)}
                        </span>
                    </div>
                </div>
                <div className='cta'>
                    <Link
                        href={getPaymentUrl(
                            data?.name,
                            data?.Batches && data?.Batches?.length > 0 && data?.Batches[0]?.id,
                            data?.url,
                            null,
                            _params
                        )}
                    >
                        <a
                            className='kw-btn-orange d-flex align-items-center justify-content-center'
                            onClick={handleEvent}
                        >
                            Enroll now <i className='icon-arrow-right-line-right fw-bold'></i>
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CourseCardThree;
