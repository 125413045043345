import React, { useState } from 'react';
import Slider from 'react-slick';
// import CourseCardTwo from '../../cards/CourseCardTwo';
import CourseCardFive from '../../cards/CourseCardFive';
import SyllabusForm from '../../common/SyllabusForm';

const CarouselSettings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 3.3,
  slidesToScroll: 3,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3.1,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        arrows: false,
        slidesToShow: 1.08,
        slidesToScroll: 1,
      },
    },
  ],
};

const CoursesListView = ({ sectionData }) => {
  const [open, setOpen] = useState(false);
  const [course, setCourse] = useState({});

  if (!sectionData.HomepageSections.length) {
    return <></>;
  }
  return (
    <>
      <div className='courses-list-view' style={{ backgroundColor: sectionData?.sectionBgColor }}>
        <div className='edu-section-gap kw-section-gap'>
          <div className='container'>
            <div className='section-title'>
              <h3 className='title' style={{ color: sectionData?.textColor }}>
                {sectionData?.title}
              </h3>
            </div>
            <Slider className='slick-activation-wrapper course-activation-3 edu-slick-button' {...CarouselSettings}>
              {sectionData.HomepageSections &&
                sectionData.HomepageSections.length &&
                sectionData.HomepageSections.map((item) => (
                  <div className='single-slick-card' key={item?.id}>
                    <CourseCardFive wsInfo={item?.Workshop} setOpen={setOpen} setCourse={setCourse} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
      <SyllabusForm open={open}
        setOpen={setOpen}
        course={{ name: course?.name, id: course?.id, kylasProductId: course?.kylasProductId }} />
    </>
  );
};

export default CoursesListView;
